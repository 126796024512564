
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";


import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "Projet",
        key: "pro_nom_projet",
        sortable: false,
      },
      {
        name: "Date commande",
        key: "toc_date_creation",
        sortable: true,
      },
      {
        name: "Livraison estimée",
        key: "date_ldc",
        sortable: true,
      },
      {
        name: "Montants HT",
        key: "tof_total_ht",
        sortable: true,
      },
      {
        name: "Statut",
        key: "etat_txt",
        sortable: true,
      },
      {
        name: "Contenu",
        key: "toc_etat_support",
        sortable: true,
      },
      {
        name: "Afficher",
        key: "toc_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Demandeurs",
        key: "con_seq",
        label: "con_prenom_nom",
      },
      {
        name: "Status",
        key: "etat_txt",
        label: "etat_txt",
        default : ["En cours de traitement","Partiellement livrée", "Production en cours"]
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "num_cmd_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "toc_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "etat_txt",
      "Montant HT": "tof_total_ht",
      "Date LDC": "date_ldc",
      Fraicheur: "fraicheur",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (data) => {
      if (data.etat_txt == t("En cours de traitement"))
        return "btn-icon-dark bg-white";
      if (data.etat_txt == t("Partiellement livrée"))
        return "btn-icon-warning bg-white";
      if (data.etat_txt == t("Commande livrée"))
        return "btn-icon-success bg-white";
      if (data.etat_txt == t("Production en cours"))
        return "btn-icon-warning bg-white";
    };

    const styleImgStatus = (data) => {
      if (data.etat_txt == t("En cours de traitement"))
        return "media/icons/duotune/arrows/arr069.svg";
      if (data.etat_txt == t("Partiellement livrée"))
        return "media/icons/duotune/arrows/arr084.svg";
      if (data.etat_txt == t("Commande livrée"))
        return "media/icons/duotune/arrows/arr085.svg";
      if (data.etat_txt == t("Production en cours"))
        return "media/icons/duotune/arrows/arr031.svg";
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.date_old_ldc
      )} ${t("au")} ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des commandes en cours", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await mAxiosApi.prototype.getAxios("/getCommandes/-1");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      // console.log(myList.results);

      for (
        let index = 0, l = state.initialMyList.length;
        index < l;
        index += 1
      ) {
        const item: any = state.initialMyList[index];
        item.etat_txt = "En cours de traitement";

        if (
          item.date_ldc
        ) {
          item.etat_txt = "Production en cours"
        }

        if (
          item.toc_etat_livraison_min == 1 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Partiellement livrée";
        }

        if (
          item.toc_etat_livraison_min == 2 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Commande livrée";
        }
      }

      state.loaderEnabled = false;
      if (router.currentRoute.value.params.code) {
        state.search = router.currentRoute.value.params.code as string;
        affDetail();
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
    };
  },
});
